import React, { FC } from 'react'

type Props = {
  onValidClick: () => void
  onDeclineClick: () => void
}

const CellActions: FC<Props> = ({ children, onValidClick, onDeclineClick }) => (
  <>
    {children}
    <button type='button' onClick={onValidClick}>
      ✔️
    </button>
    <button type='button' onClick={onDeclineClick}>
      ❌
    </button>
  </>
)

export default CellActions
