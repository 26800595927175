import { Loading, Frame, Page, Tabs } from '@shopify/polaris'
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  // Membership,
  Account,
  Location as SnapAppLocation,
} from 'src/types/SnapApi'
import { withSnapAppLogin } from 'src/utils/ProtectedRoutes'
import { apis } from 'src/utils/api'

import Import from './components/Import'

import './AccountShow.module.css'

const AccountShow: React.FC = () => {
  const { id: accountId } = useParams<{ id: string }>()

  const [account, setAccount] = useState<Account>()
  // const [admins, setAdmins] = useState<Membership[] | null>(null)
  // const [isloading, setIsloading] = useState<boolean>(false)

  const [locations, setLocations] = useState<SnapAppLocation[] | null>(null)
  const [selectedTab, setSelectedTab] = useState<number>(0)

  const handleTabChange = useCallback(
    (selectedTabIndex) => setSelectedTab(selectedTabIndex),
    []
  )

  // Create new token for the client
  // const handleCreateToken = useCallback(async () => {
  //   setIsloading(true)
  //
  //   const response = await apis.snapApp.put(
  //     `v2/accounts/${accountId}/partner_api_token`
  //   )
  //
  //   setIsloading(false)
  //
  //   setAccount(response.data)
  // }, [accountId])

  // Fetch the account
  useEffect(() => {
    const fetchAccount = async () => {
      const response = await apis.snapApp.get(`backoffice/accounts/${accountId}`)

      setAccount(response.data)
    }

    fetchAccount()
  }, [accountId])

  // fetch the admin
  // useEffect(() => {
  //   const fetchAdmins = async () => {
  //     const response = await apis.snapApp.get<Membership[]>(
  //       `v2/accounts/${accountId}/memberships?active=true&role[]=owner&role[]=main_owner`
  //     )
  //     setAdmins(response?.data || null)
  //   }
  //   fetchAdmins()
  // }, [accountId])

  // fetch the locations
  useEffect(() => {
    const fetchLocations = async () => {
      const locations = await apis.snapApp.get<SnapAppLocation[]>(
        `backoffice/accounts/${accountId}/locations`
      )

      setLocations(locations.data)
    }
    fetchLocations()
  }, [accountId])

  if (!account || !locations) {
    return (
      <Frame>
        <Loading />
      </Frame>
    )
  }

  const tabs = [
    {
      id: 'import-1',
      content: 'Import tool',
      panelID: 'import-content-1',
      component: Import,
      props: {
        account,
        locations,
      },
    },
  ]

  const Component = tabs[selectedTab].component

  return (
    <Page
      fullWidth
      title={account?.name}
      breadcrumbs={[{ content: 'Accounts', url: '/accounts' }]}
    >
      <Tabs tabs={tabs} selected={selectedTab} onSelect={handleTabChange}>
        <Component {...(tabs[selectedTab].props as any)} />
      </Tabs>
    </Page>
  )
}

export default withSnapAppLogin(AccountShow)
