import {
  Button,
  ButtonGroup,
  Card,
  Form,
  FormLayout,
  InlineError,
} from '@shopify/polaris'
import React, { ChangeEvent, FC, useState } from 'react'

import styles from './index.module.css'

interface Props {
  canDownload?: boolean
  downloadFile: () => void
  onSubmit: (value: any) => void
  uploadFile: () => void
  downloadModel: () => void
}

const FormImport: FC<Props> = ({
  downloadFile,
  onSubmit,
  uploadFile,
  canDownload = false,
  downloadModel,
}) => {
  const [formatError, setFormatError] = useState(false)
  const [canDownloadFile, setCanDownloadFile] = useState(false)

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const currentFileType = event?.target?.files?.[0]?.type

    if (currentFileType && currentFileType.indexOf('text/csv') > -1) {
      setFormatError(false)

      onSubmit(event.target.files?.[0])
      setCanDownloadFile(true)
    } else {
      setFormatError(true)
    }
  }

  return (
    <Form onSubmit={() => ({})}>
      <Card sectioned>
        <FormLayout>
          {formatError && (
            <InlineError
              message='The file needs to be a csv'
              fieldID='import'
            />
          )}
          <input
            id='import'
            className={styles.InputUpload}
            accept='.csv'
            type='file'
            onChange={handleChange}
            name='importFile'
          />
          <ButtonGroup>
            <Button disabled={!canDownloadFile} onClick={downloadFile}>
              Download file
            </Button>
            <Button primary disabled={!canDownload} onClick={uploadFile}>
              Launch the import
            </Button>
            <Button onClick={downloadModel}>Download the file model</Button>
          </ButtonGroup>
        </FormLayout>
      </Card>
    </Form>
  )
}

export default FormImport
