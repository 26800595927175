/* eslint-disable react/jsx-props-no-spreading */
import { Page, Card, DataTable } from '@shopify/polaris'
import IBAN from 'iban'
import React, { useState, Dispatch, SetStateAction } from 'react'
import CSVReader from 'react-csv-reader'

import { isValidEmail, isValidDate } from './utils'

type ErrorData = [number, string]
const FOREIGN_WORKERS_VALID_KEYS = [
  'Carte de séjour : Compétences et talents',
  'Carte de résident longue durée-UE',
  'Carte de séjour temporaire : Vie privée et familiale',
  'Visa de long séjour',
  'Autorisation provisoire de séjour',
]

const ImportValidator: React.FC = () => {
  const [hasValidated, setHasValidated] = useState(false)
  const [emailErrors, setEmailErrors] = useState<ErrorData[]>([])
  const [ibanErrors, setIbanErrors] = useState<ErrorData[]>([])
  const [foreignWorkerErrors, setForeignWorkerErrors] = useState<ErrorData[]>(
    []
  )
  const [contractStartDateErrors, setContractStartDateErrors] = useState<
    ErrorData[]
  >([])
  const [contractEndDateErrors, setContractEndDateErrors] = useState<
    ErrorData[]
  >([])
  const [birthDateErrors, setBirthDateErrors] = useState<ErrorData[]>([])
  const [medicalExamErrors, setMedicalExamErrors] = useState<ErrorData[]>([])
  const [expiryDateErrors, setExpiryDateErrors] = useState<ErrorData[]>([])

  const dateKeys: {
    [key: string]: React.Dispatch<React.SetStateAction<ErrorData[]>>
  } = {
    date_d_embauche: setContractStartDateErrors,
    date_de_sortie: setContractEndDateErrors,
    date_de_naissance: setBirthDateErrors,
    date_derni_re_visite_m_dicale: setMedicalExamErrors,
    date_d_expiration_du_document: setExpiryDateErrors,
  }

  const setError = (
    setter: Dispatch<SetStateAction<ErrorData[]>>,
    value: ErrorData
  ) => {
    setter((prevErrors: ErrorData[]) => {
      const newErrors = Array.from(prevErrors)
      newErrors.push(value)

      return newErrors
    })
  }
  const handleFileLoaded = (data: any[]) => {
    setEmailErrors([])
    setIbanErrors([])
    setContractStartDateErrors([])
    setHasValidated(true)

    data.forEach((row, index) => {
      const { email, iban } = row
      // eslint-disable-next-line no-underscore-dangle
      const foreignWorkerErrors = row.type_de_document__travailleurs__trangers_

      if (email && email.length > 0 && !isValidEmail(email)) {
        setError(setEmailErrors, [index + 1, email])
      }

      if (iban && iban.length > 0 && !IBAN.isValid(iban)) {
        setError(setIbanErrors, [index + 1, iban])
      }

      if (
        foreignWorkerErrors &&
        foreignWorkerErrors.length > 0 &&
        !FOREIGN_WORKERS_VALID_KEYS.includes(foreignWorkerErrors)
      ) {
        setError(setForeignWorkerErrors, [index + 1, foreignWorkerErrors])
      }

      Object.keys(dateKeys).forEach((dateKey) => {
        const date = row[dateKey]
        if (date && date.length > 0 && !isValidDate(date)) {
          setError(dateKeys[dateKey], [index + 1, date])
        }
      })
    })
  }

  const isValid =
    emailErrors.length === 0 &&
    ibanErrors.length === 0 &&
    contractStartDateErrors.length === 0 &&
    contractEndDateErrors.length === 0 &&
    birthDateErrors.length === 0 &&
    medicalExamErrors.length === 0 &&
    foreignWorkerErrors.length === 0 &&
    expiryDateErrors.length === 0

  return (
    <Page
      fullWidth
      title='Import validation'
      breadcrumbs={[{ content: 'Home', url: '/' }]}
    >
      <Card sectioned>
        <Card.Section>
          <p>
            Select a .csv file to check if the values of the columns are
            correctly formatted and have a correct values. Eg. IBAN check /
            foreign workers values, etc.
          </p>

          <CSVReader
            parserOptions={{
              header: true,
              transformHeader: (header) =>
                header.toLowerCase().replace(/\W/g, '_'),
            }}
            onFileLoaded={handleFileLoaded}
          />
        </Card.Section>
      </Card>

      {hasValidated && isValid && (
        <Card sectioned title='Your import is valid!'>
          <img
            alt='Michelle Obama clapping'
            src='https://media.giphy.com/media/26tkmwzpL7hyioxri/giphy.gif'
          />
        </Card>
      )}

      {emailErrors.length > 0 && (
        <Card sectioned title='Invalid emails'>
          <DataTable
            columnContentTypes={['numeric', 'text']}
            headings={['Line', 'Value']}
            rows={emailErrors}
          />
        </Card>
      )}

      {ibanErrors.length > 0 && (
        <Card sectioned title='Invalid IBANs'>
          <DataTable
            columnContentTypes={['numeric', 'text']}
            headings={['Line', 'Value']}
            rows={ibanErrors}
          />
        </Card>
      )}
      {contractStartDateErrors.length > 0 && (
        <Card sectioned title='Contract Start - invalid date'>
          <DataTable
            columnContentTypes={['numeric', 'text']}
            headings={['Line', 'Value']}
            rows={contractStartDateErrors}
          />
        </Card>
      )}
      {contractEndDateErrors.length > 0 && (
        <Card sectioned title='Contract End  - invalid date'>
          <DataTable
            columnContentTypes={['numeric', 'text']}
            headings={['Line', 'Value']}
            rows={contractEndDateErrors}
          />
        </Card>
      )}
      {birthDateErrors.length > 0 && (
        <Card sectioned title='Birthdate - invalid date'>
          <DataTable
            columnContentTypes={['numeric', 'text']}
            headings={['Line', 'Value']}
            rows={birthDateErrors}
          />
        </Card>
      )}
      {medicalExamErrors.length > 0 && (
        <Card sectioned title='Medical exams date - invalid date'>
          <DataTable
            columnContentTypes={['numeric', 'text']}
            headings={['Line', 'Value']}
            rows={medicalExamErrors}
          />
        </Card>
      )}
      {foreignWorkerErrors.length > 0 && (
        <Card sectioned title='Travailleur étranger - wrong value'>
          <p>
            Accepted values:{' '}
            <ul>
              {FOREIGN_WORKERS_VALID_KEYS.map((value) => (
                <li>{value}</li>
              ))}
            </ul>
          </p>
          <DataTable
            columnContentTypes={['numeric', 'text']}
            headings={['Line', 'Value']}
            rows={foreignWorkerErrors}
          />
        </Card>
      )}
      {expiryDateErrors.length > 0 && (
        <Card sectioned title='Expiry document - invalid date'>
          <DataTable
            columnContentTypes={['numeric', 'text']}
            headings={['Line', 'Value']}
            rows={expiryDateErrors}
          />
        </Card>
      )}
    </Page>
  )
}

export default ImportValidator
