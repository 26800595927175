import { Icon } from '@shopify/polaris'
import classnames from 'classnames'
import React, { FC } from 'react'
import { FaTrashAlt, FaEraser } from 'react-icons/fa'

import Cell from './cell'
import { useTable } from './helpers'
import styles from './index.module.css'
import Scroller from './scroller'
import TableActions from './tableActions'
import { ColumnsErrorType, ConfigurationType } from './types'

type Props = {
  data?: any
  errors?: any
  columnErrors?: ColumnsErrorType
  updateCell: (indexCell: number, indexRow: number, value: any) => void
  updateColumn: (indexColumn: number, value: any) => void
  removeRow: (indexRow: number) => void
  removeColumn: (indexColumn: number) => void
  clearColumn: (indexColumn: number) => void
  addRow: () => void
  addColumn: () => void
  duplicateRow: () => void
  configuration: ConfigurationType[]
}

type ActionsProps = {
  onDelete(): void
  onReset?: () => void
}
const ActionListWithMediaExample: FC<ActionsProps> = ({
  onDelete,
  onReset,
}) => {
  return (
    <div className={styles.ContainerIcons}>
      <div
        onClick={onDelete}
        onKeyPress={onDelete}
        className={styles.IconAction}
      >
        <Icon source={FaTrashAlt} />
      </div>
      {onReset && (
        <div
          onClick={onReset}
          onKeyPress={onReset}
          className={styles.IconAction}
        >
          <Icon source={FaEraser} />
        </div>
      )}
    </div>
  )
}

const ImportTable: FC<Props> = ({
  data,
  errors,
  columnErrors,
  updateCell,
  updateColumn,
  removeRow,
  removeColumn,
  clearColumn,
  addRow,
  duplicateRow,
  addColumn,
  configuration,
}) => {
  const { columns, rows } = useTable(data)

  if (!data) {
    return <p>No file exists yet</p>
  }

  return (
    <div>
      <div className={styles.TableContainer}>
        <table>
          <thead>
            <tr className={styles.TableHeader}>
              <th className={styles.BackgroundGrey} key='column-header-line'>
                Line
              </th>
              <th className={styles.BackgroundGrey} key='column-header-action'>
                Actions
              </th>
              {columns.map((column: any, index: number) => (
                <th
                  key={`column-action-${index + 1}`}
                  className={styles.BackgroundGrey}
                >
                  <ActionListWithMediaExample
                    onDelete={() => removeColumn(index)}
                    onReset={() => clearColumn(index)}
                  />
                </th>
              ))}
            </tr>
            <tr
              className={`${styles.TableColumns} ${
                columnErrors?.total ? styles.WarningBackground : ''
              }`}
            >
              <th
                className={classnames(styles.BackgroundGrey, styles.Center)}
                key='column-line'
              >
                0
              </th>
              <th className={styles.BackgroundGrey} key='column-action'>
                &nbsp;
              </th>
              {columns.map((column: any, index: number) => {
                const style: any = {}

                if (columnErrors?.column?.[index]) {
                  style.background = '#ef374b'
                }

                return (
                  <Cell
                    as='th'
                    key={`column-${index + 1}`}
                    data={column}
                    style={style}
                    onChange={(value) => updateColumn(index, value)}
                    errors={columnErrors?.column?.[index] || []}
                  />
                )
              })}
            </tr>
          </thead>
          <tbody>
            {rows.map((row: any, indexRow) => {
              const cells = row.map((cell: any, indexCell: number) => {
                const style: any = {}

                if (errors?.[indexRow]?.[indexCell]) {
                  style.background = '#ef374b'
                }

                return (
                  <Cell
                    key={`cell-${indexRow}-${indexCell}`}
                    data={cell}
                    style={style}
                    onChange={(value) => updateCell(indexCell, indexRow, value)}
                    errors={errors?.[indexRow]?.[indexCell] || []}
                    configuration={configuration[indexCell]?.cell}
                  />
                )
              })

              const errorStyle: any = {}

              if (errors?.[indexRow]) {
                errorStyle.background = '#fff6f6'
              }

              return (
                <tr
                  key={`row-${indexRow}`}
                  style={errorStyle}
                  className={styles.TableBodyTrame}
                >
                  <td className={styles.Center} key={`row-line-${indexRow}`}>
                    {indexRow + 1}
                  </td>
                  <td
                    key={`row-action-${indexRow}`}
                    className={styles.BackgroundGrey}
                  >
                    <ActionListWithMediaExample
                      onDelete={() => removeRow(indexRow)}
                    />
                  </td>
                  {cells}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <div className={styles.AddRow}>
        <TableActions
          addRow={addRow}
          duplicateRow={duplicateRow}
          addColumn={addColumn}
        />
      </div>
      <Scroller />
    </div>
  )
}

export default ImportTable
