/* eslint-disable react/jsx-props-no-spreading */
import {
  Select,
  Stack,
  TextField,
  Pagination,
  Link,
  Page,
  Card,
} from '@shopify/polaris'
import classnames from 'classnames'
import React, { useMemo, useEffect, useState, useCallback } from 'react'
import { useTable } from 'react-table'

import { Account } from 'src/types/SnapApi'
import { withSnapAppLogin } from 'src/utils/ProtectedRoutes'
import { apis } from 'src/utils/api'
import { formatDateTime } from 'src/utils/dates'

import styles from './AccountsIndex.module.css'

const AccountsIndex: React.FC = () => {
  const [accountRows, setAccounts] = useState<Account[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [statusFilter, setStatusFilter] = useState('all')

  const [search, setSearch] = useState('')

  useEffect(() => {
    const fetchAccountsIndex = async () => {
      const response = await apis.snapApp.get('backoffice/accounts', {
        params: {
          page: currentPage,
          search,
          sort_by: 'created_at',
        },
      })
      setAccounts(response.data)
    }
    fetchAccountsIndex()
  }, [currentPage, search])

  const columns = useMemo(
    () => [
      {
        Header: '-',
        columns: [
          {
            Header: 'Creation date',
            accessor: (account: Account) =>
              formatDateTime(account.created_at, true),
            Cell: (row: any) => (
              <Link url={`/accounts/${row.cell.row.original.id}`}>
                {row.value}
              </Link>
            ),
          },
          {
            Header: 'Name',
            accessor: 'name',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            Cell: (row: any) => {
              return (
                <>
                  <div
                    className={classnames(
                      styles.StatusBadge,
                      styles[accountStatus(row.cell.row.original)]
                    )}
                  />{' '}
                  <Link url={`/accounts/${row.cell.row.original.id}`}>
                    {row.value}
                  </Link>
                </>
              )
            },
          },
          {
            Header: 'Users',
            accessor: 'membership_count',
            Cell: (row: any) => (
              <Link url={`/accounts/${row.cell.row.original.id}`}>
                {row.value}
              </Link>
            ),
          },
          {
            Header: 'Shifts',
            accessor: 'shift_count',
            Cell: (row: any) => (
              <Link url={`/accounts/${row.cell.row.original.id}`}>
                {row.value}
              </Link>
            ),
          },
          // ICI RAJOUTER SI PAUSE ACTIVER
          {
            Header: 'Teams',
            accessor: 'team_count',
            Cell: (row: any) => (
              <Link url={`/accounts/${row.cell.row.original.id}`}>
                {row.value}
              </Link>
            ),
          },
          {
            Header: 'Contact',
            accessor: 'owner_name',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            Cell: (row: any) => (
              <Link url={`/accounts/${row.cell.row.original.id}`}>
                {row.value}{' '}
                <i>
                  ({row.cell.row.original.email} /{' '}
                  {row.cell.row.original.phone_number})
                </i>
              </Link>
            ),
          },
          {
            Header: 'Last shift',
            accessor: (account: Account) =>
              formatDateTime(account.latest_shift_created_at),
            Cell: (row: any) => (
              <Link url={`/accounts/${row.cell.row.original.id}`}>
                {row.value}
              </Link>
            ),
          },
          {
            Header: 'Activation',
            accessor: (account: Account) =>
              formatDateTime(
                account.subscription_activated_at ||
                  account.subscription_reactivated_at
              ),
            Cell: (row: any) => (
              <Link url={`/accounts/${row.cell.row.original.id}`}>
                {row.value}
              </Link>
            ),
          },
          {
            Header: "Combo's url",
            accessor: '',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            Cell: (row: any) => {
              return (
                <Link
                  url={`https://${row.cell.row.original.subdomain}.app.combohr.com`}
                  key='emerald-silk-gown'
                  external
                >
                  URL
                </Link>
              )
            },
          },
        ],
      },
    ],
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data: accountRows,
  })

  const handlePrevious = useCallback(() => {
    setCurrentPage((currentPage) => currentPage - 1)
  }, [setCurrentPage])

  const handleNext = useCallback(() => {
    setCurrentPage((currentPage) => currentPage + 1)
  }, [setCurrentPage])

  const handleSearch = (newSearch: string) => {
    setCurrentPage(1)
    setSearch(newSearch)
  }
  return (
    <Page
      fullWidth
      title='Latest accounts'
      breadcrumbs={[{ content: 'Home', url: '/' }]}
    >
      <Card sectioned>
        <Card.Section>
          <Stack>
            <Select
              label='Filtre'
              options={[
                { label: 'All', value: 'all' },
                { label: 'Black', value: 'black' },
                { label: 'Red', value: 'red' },
                { label: 'Orange', value: 'orange' },
                { label: 'Green', value: 'green' },
              ]}
              onChange={setStatusFilter}
              value={statusFilter}
            />
            <TextField
              onChange={handleSearch}
              value={search}
              label='Search by account name, owner name or email'
            />
          </Stack>
        </Card.Section>
        <Card.Section>
          <Pagination
            plain
            label={`Page: ${currentPage}`}
            hasPrevious
            onPrevious={handlePrevious}
            hasNext
            onNext={handleNext}
          />
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                const status = accountStatus(row.values)
                if (statusFilter !== 'all' && status !== statusFilter) {
                  return
                }

                prepareRow(row)
                return (
                  <tr
                    {...row.getRowProps(() => ({
                      style: {
                        color: status,
                      },
                    }))}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
          <Pagination
            plain
            label={`Page: ${currentPage}`}
            hasPrevious
            onPrevious={handlePrevious}
            hasNext
            onNext={handleNext}
          />
        </Card.Section>
        <Card.Section title='Legend'>
          <p>
            <div className={classnames(styles.StatusBadge, styles.red)} /> Did
            not invite any users and have no shifts
          </p>
          <p>
            <div className={classnames(styles.StatusBadge, styles.orange)} />{' '}
            Invited members but has no shifts
          </p>
          <p>
            <div className={classnames(styles.StatusBadge, styles.green)} />{' '}
            Invited members and started creating shifts (less than 10)
          </p>
        </Card.Section>
      </Card>
    </Page>
  )
}

export default withSnapAppLogin(AccountsIndex)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function accountStatus(row: any): string {
  if (row.membership_count === 1 && row.shift_count === 0) {
    // Not good - did not invite any users or have no shifts
    return 'red'
  }

  if (row.shift_count === 0) {
    // Invited a user but no shifts
    return 'orange'
  }

  if (row.shift_count < 10) {
    // Invited a user and started creating shifts
    return 'green'
  }

  return ''
}
