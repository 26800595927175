import { AppProvider } from '@shopify/polaris'
import translations from '@shopify/polaris/locales/en.json'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'

import '@shopify/polaris/dist/styles.css'
import './index.css'
import App from './components/App/App'
import {
  AccountsIndex,
  ImportValidator,
  AccountShow,
  Login,
} from './scenes'
import * as serviceWorker from './serviceWorker'
import HttpsRedirect from './utils/HttpsRedirect'
import ProtectedRoutes from './utils/ProtectedRoutes'

export {}
declare global {
  interface Window {
    gapi: any
  }
}

ReactDOM.render(
  <React.StrictMode>
    <HttpsRedirect>
      <AppProvider i18n={translations}>
        <BrowserRouter>
          <Switch>
            <Route exact path='/login' component={Login} />
            <ProtectedRoutes>
              <Route exact path='/' component={App} />
              <Route exact path='/accounts' component={AccountsIndex} />
              <Route
                exact
                path='/import-validator'
                component={ImportValidator}
              />
              <Route exact path='/accounts/:id' component={AccountShow} />
              <Route exact path='/last-accounts'>
                <Redirect to='accounts' />
              </Route>
            </ProtectedRoutes>
          </Switch>
        </BrowserRouter>
      </AppProvider>
    </HttpsRedirect>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
