import { Button, Popover, ActionList } from '@shopify/polaris'
import React, { FC, useState, useCallback, useMemo } from 'react'

type Props = {
  addColumn: () => void
  addRow: () => void
  duplicateRow: () => void
}

const TableActions: FC<Props> = ({ addColumn, addRow, duplicateRow }) => {
  const [active, setActive] = useState(true)

  const toggleActive = useCallback(() => setActive((active) => !active), [])

  const activator = useMemo(
    () => (
      <Button onClick={toggleActive} disclosure>
        More actions
      </Button>
    ),
    [toggleActive]
  )

  const handleAction = (actionCB: any): any => {
    return (event: any) => {
      toggleActive()
      actionCB(event)
    }
  }

  return (
    <div>
      <Popover active={active} activator={activator} onClose={toggleActive}>
        <ActionList
          actionRole='menuitem'
          sections={[
            {
              items: [
                {
                  content: 'Create a new row',
                  helpText: 'Add a new row at the end of the table',
                  onAction: handleAction(addRow),
                },
                {
                  content: 'Duplicate last row',
                  helpText: 'Duplicate the same data from the last row',
                  onAction: handleAction(duplicateRow),
                },
                {
                  content: 'Add a new column',
                  helpText:
                    'Add a new column will add a new cell at the end of all rows',
                  onAction: handleAction(addColumn),
                },
              ],
            },
          ]}
        />
      </Popover>
    </div>
  )
}

export default TableActions
