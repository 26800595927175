import { Icon, Tooltip } from '@shopify/polaris'
import React, { FC, useCallback, useState } from 'react'
import { BsFillInfoCircleFill } from 'react-icons/bs'

import CellActions from './cellActions'
import CellInput from './cellInput'
import CellSelect from './cellSelect'

interface Props {
  as?: 'td' | 'th'
  data: string | number | null
  onChange: (value: string | number) => void
  style?: any
  errors?: {
    message?: string
    status?: string
  }[]
  configuration?: any
}

const Cell: FC<Props> = ({
  as,
  data,
  onChange,
  style,
  errors = [],
  configuration,
}) => {
  const [showInput, setShowInput] = useState(false)
  const [newValue, setNewValue] = useState<string | number>(data || '')
  const Component = as || 'td'

  let render: any = null

  const handleChange = (value: string | number) => {
    setNewValue(value)
  }

  const onDeclineClick = useCallback(() => {
    setShowInput(false)
    setNewValue('')
  }, [setShowInput, setNewValue])

  const handleValidClick = useCallback(() => {
    onChange(newValue)
    onDeclineClick()
  }, [newValue, onChange, onDeclineClick])

  const handleKeyPress = useCallback(
    (event) => {
      // Can save with the Enter key
      if (event?.key && event?.key === 'Enter') {
        // if enter is clicked with data without any changes,
        // the cell will be erase, so we hide the input
        if (newValue && newValue !== data) {
          handleValidClick()
        } else {
          setShowInput(false)
        }
      }
    },
    [handleValidClick, newValue, data, setShowInput]
  )

  // Change component for select/option
  if (configuration?.type?.includes('select')) {
    render = !showInput ? (
      data
    ) : (
      <CellActions
        onValidClick={handleValidClick}
        onDeclineClick={onDeclineClick}
      >
        <CellSelect
          value={data as any}
          options={configuration.options}
          onChange={handleChange}
          defaultValue={configuration?.defaultValue}
          hasFirstOption={configuration?.hasFirstOption}
        />
      </CellActions>
    )
  } else {
    render = !showInput ? (
      data
    ) : (
      <CellActions
        onValidClick={handleValidClick}
        onDeclineClick={onDeclineClick}
      >
        <CellInput
          type={configuration?.type}
          value={data as any}
          defaultValue={configuration?.defaultValue}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
        />
      </CellActions>
    )
  }

  return (
    <Component
      style={style}
      onClick={() => {
        if (!showInput) {
          setShowInput(true)
        }
      }}
    >
      {errors?.length ? (
        <div>
          <Tooltip
            dismissOnMouseOut
            content={errors.reduce(
              (acc: string, error: any) =>
                !acc.length ? error.message : `${acc}, ${error.message}`,
              ''
            )}
          >
            <Icon source={BsFillInfoCircleFill} />
          </Tooltip>
          {render}
        </div>
      ) : (
        render
      )}
    </Component>
  )
}

export default Cell
