import { Card, Layout, Link, TextStyle } from '@shopify/polaris'
import React from 'react'

import { Account, Location as SnapAppLocation } from 'src/types/SnapApi'

import ImportConfigurationContainer from './configurationContainer'
import Historic from './historic'
import styles from './index.module.css'

interface Props {
  account: Account
  locations: SnapAppLocation[]
}

const Information = () => (
  <Card title='How the import tool is working ?'>
    <Card.Section>
      <p>
        This form lets us to import the users/employees from a society. We
        recommand to be watchful on all the data that the client will set inside
        the csv file. This task could take a amount of time, so don't upload it
        many times if it doesn't work at the first time.
      </p>

      <Link
        external
        url='https://www.notion.so/snapshift/b23eee6dc0e240838307788b19bd74ec?v=af64190121534b7ab28924d0fe6409d3'
      >
        List of all the formats for values
      </Link>
    </Card.Section>
  </Card>
)

const Import: React.FC<Props> = ({ account, locations }) => (
  <Card.Section>
    <div className={styles.AlertInformation}>
      <TextStyle variation='negative'>
        ⚠️ Warning, this part is still in Work In Progress and if you have
        error, tell us inside the import-client's channel. Actually some errors
        can be fired on Safari or Firefox, we recommand you to use Chrome or
        Opera ⚠️
      </TextStyle>
    </div>
    <Layout>
      <Layout.Section secondary>
        <Information />
      </Layout.Section>
      <ImportConfigurationContainer account={account} locations={locations} />
      <Historic account={account} />
    </Layout>
  </Card.Section>
)

export default Import
