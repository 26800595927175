import axios, { AxiosInstance } from 'axios'

import {
  SNAP_APP_ACCESS_TOKEN,
  SNAP_APP_REFRESH_TOKEN,
  SNAP_APP_EXPIRES_IN,
  SNAP_ID_ACCESS_TOKEN,
  SNAP_ID_REFRESH_TOKEN,
  SNAP_ID_EXPIRES_IN,
} from './const'

type ApiTypes = 'snapApp' | 'snapAppGateway' | 'id'

const apis: { [key in ApiTypes]: AxiosInstance } = {
  snapApp: axios.create({
    baseURL: `${process.env.SNAP_APP_API_URL}/api`,
  }),
  snapAppGateway: axios.create({
    baseURL: `${process.env.SNAP_APP_API_URL}/gateway/api/v1`,
  }),
  id: axios.create({
    baseURL: `${process.env.SNAP_ID_API_URL}`,
  }),
}

const COOKIE_KEYS: { [key in ApiTypes]: string[] } = {
  snapApp: [SNAP_APP_ACCESS_TOKEN, SNAP_APP_REFRESH_TOKEN, SNAP_APP_EXPIRES_IN],
  snapAppGateway: [
    SNAP_APP_ACCESS_TOKEN,
    SNAP_APP_REFRESH_TOKEN,
    SNAP_APP_EXPIRES_IN,
  ],
  id: [SNAP_ID_ACCESS_TOKEN, SNAP_ID_REFRESH_TOKEN, SNAP_ID_EXPIRES_IN]
}

const getAccessToken = (apiKey: ApiTypes): string | null => {
  return apiKey === 'snapApp' || apiKey === 'snapAppGateway'
    ? localStorage.getItem(SNAP_APP_ACCESS_TOKEN)
    : localStorage.getItem(SNAP_ID_ACCESS_TOKEN)
}
const setApiAuthorizations = (): void => {
  Object.keys(apis).forEach((apiKey: string) => {
    const api = apis[apiKey as ApiTypes]
    const token = getAccessToken(apiKey as ApiTypes)

    api.interceptors.request.use((config) => {
      config.headers.Authorization = `Bearer ${token}`
      config.headers['Account-Id'] = `3456`
      config.headers['Backoffice'] = true
      return config
    })
    api.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error?.response?.status === 401) {
          logout(apiKey as ApiTypes)
        } else {
          return Promise.reject(error)
        }
      }
    )
  })
}

export const logout = (apiKey?: ApiTypes): void => {
  if (typeof apiKey === 'string') {
    COOKIE_KEYS[apiKey].forEach((cookieKey) =>
      localStorage.removeItem(cookieKey)
    )
  } else {
    Object.keys(apis).forEach((apiKey: string) => {
      COOKIE_KEYS[apiKey as ApiTypes].forEach((cookieKey) =>
        localStorage.removeItem(cookieKey)
      )
    })
  }

  window.location.reload()
}

export { apis, setApiAuthorizations }
