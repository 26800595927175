import React, { useEffect } from 'react'

const isProduction = process.env.NODE_ENV === 'production'
const HttpsRedirect: React.FC = ({ children }) => {
  useEffect(() => {
    if (!isProduction) {
      return
    }

    const url = window.location.href
    if (!url.includes('https')) {
      window.location.href = url.replace('http://', 'https://')
    }
  }, [])

  return <>{children}</>
}

export default HttpsRedirect
