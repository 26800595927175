/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { Redirect } from 'react-router-dom'

import { SnapAppLogin } from 'src/components/Login'

import { setApiAuthorizations } from '../api'
import { isLoggedIn, isLoggedInSnapshift } from '../loggedIn'

interface Props {
  children: any
}

const ProtectedRoutes: React.FC<Props> = ({ children }) => {
  if (!isLoggedIn()) {
    return <Redirect to='/login' />
  }

  setApiAuthorizations()

  return <>{children}</>
}

export default ProtectedRoutes

export const withSnapAppLogin = (Component: any) => {
  return (props: any) => {
    if (!isLoggedInSnapshift()) {
      return <SnapAppLogin />
    }

    return <Component {...props} />
  }
}
