import { Form, FormLayout, Modal, TextField } from '@shopify/polaris'
import React, { useState } from 'react'

import { apis } from 'src/utils/api'
import { login } from 'src/utils/login'

const LOCAL_STORAGE_SNAP_EMAIL_KEY = 'snap-email'

const SnapAppLogin = () => {
  const [email, setEmail] = useState(
    localStorage.getItem(LOCAL_STORAGE_SNAP_EMAIL_KEY) || '@snapshift.co'
  )
  const [password, setPassword] = useState('')
  const [error, setError] = useState(false)

  const handleLogin = async () => {
    const body = {
      client_id: process.env.SNAPSHIFT_CLIENT_ID,
      client_secret: process.env.SNAPSHIFT_CLIENT_SECRET,
      grant_type: 'password',
      username: email,
      password,
    }

    localStorage.setItem(LOCAL_STORAGE_SNAP_EMAIL_KEY, email)

    try {
      const response = await apis.snapApp.post('v2/oauth/token', body)

      login({
        accessToken: response.data.access_token,
        refreshToken: response.data.refresh_token,
        expiresIn: response.data.expires_in,
        type: 'snap-app',
      })
      window.location.reload()
    } catch {
      setError(true)
    }
  }

  return (
    <Modal
      open
      onClose={() => null}
      title='Combo credentials'
      primaryAction={{
        content: 'Login',
        onAction: handleLogin,
      }}
    >
      <Modal.Section>
        <Form onSubmit={handleLogin}>
          <FormLayout>
            <TextField
              type='text'
              label='Email'
              value={email}
              onChange={setEmail}
            />
            <TextField
              type='password'
              label='Password'
              value={password}
              onChange={setPassword}
              error={error}
            />
          </FormLayout>
        </Form>
      </Modal.Section>
    </Modal>
  )
}
export default SnapAppLogin
