import {
  SNAP_ID_ACCESS_TOKEN,
  SNAP_ID_REFRESH_TOKEN,
  SNAP_ID_EXPIRES_IN,
  SNAP_APP_ACCESS_TOKEN,
  SNAP_APP_REFRESH_TOKEN,
  SNAP_APP_EXPIRES_IN,
} from './const'

interface LoginProps {
  accessToken: string
  refreshToken: string
  expiresIn: number
  type: 'snap-app' | 'snap-id'
}

export const login = ({
  accessToken,
  refreshToken,
  expiresIn,
  type,
}: LoginProps): void => {
  if (type === 'snap-app') {
    localStorage.setItem(SNAP_APP_ACCESS_TOKEN, accessToken)
    localStorage.setItem(SNAP_APP_REFRESH_TOKEN, refreshToken)
    localStorage.setItem(
      SNAP_APP_EXPIRES_IN,
      new Date(new Date().getTime() + expiresIn * 1000).getTime().toString()
    )
  } else {
    localStorage.setItem(SNAP_ID_ACCESS_TOKEN, accessToken)
    localStorage.setItem(SNAP_ID_REFRESH_TOKEN, refreshToken)
    localStorage.setItem(
      SNAP_ID_EXPIRES_IN,
      new Date(new Date().getTime() + expiresIn * 1000).getTime().toString()
    )
  }
}
