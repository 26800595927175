import { Card, Layout, TextStyle, Spinner } from '@shopify/polaris'
import moment from 'moment'
import React, { FC, useEffect, useMemo, useState } from 'react'

import { Account } from 'src/types/SnapApi'
import { apis } from 'src/utils/api'

const STATUS = {
  CREATED: 'created',
  PROCESSING: 'processing',
  FAILED: 'failed',
  SUCCEED: 'succeed',
}

export type HistoricType = {
  id: number
  user_id: number
  status: 'created' | 'processing' | 'failed' | 'succeed'
  job_id?: string
  started_at: string
  finished_at?: string
  error_message?: string
  user_name: string
  user_email: string
  attachment_url?: string
}

interface Props {
  account: Account
}

const Historic: FC<Props> = ({ account }) => {
  const [loading, setLoading] = useState(false)
  const [historic, setHistoric] = useState<HistoricType[] | null>(null)

  const callApi = async () => {
    setLoading(true)

    await apis.snapApp
      .get(`backoffice/accounts/${account.id}/imports`)
      .then(({ data }) => {
        setHistoric(data)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (!historic) {
      callApi()
    }
  }, [])

  const renderStatusColor = (historic: HistoricType) => {
    switch (historic.status) {
      case STATUS.CREATED:
        return 'subdued'
      case STATUS.PROCESSING:
        return 'strong'
      case STATUS.FAILED:
        return 'negative'
      default:
        return 'positive'
    }
  }

  const renderHistoric = useMemo(() => {
    if (!historic?.length) {
      return (
        <Card.Section>
          No exports were uploaded for this account yet
        </Card.Section>
      )
    }

    return historic.map((historicFile) => (
      <Card.Section key={historicFile.id}>
        <TextStyle variation={renderStatusColor(historicFile)}>
          {historicFile?.started_at &&
            `${moment(historicFile.started_at).format('YYYY-MM-DD HH:mm')} - `}
          {historicFile.status} by {historicFile.user_name}
        </TextStyle>
        {historicFile.status === STATUS.FAILED && (
          <>
            <br />
            Error message:&nbsp;
            <TextStyle variation='negative'>
              {historicFile.error_message}
            </TextStyle>
          </>
        )}
      </Card.Section>
    ))
  }, [historic])

  return (
    <Layout.Section>
      <Card
        title='Historic of upload'
        actions={[{ content: 'Reload', onAction: callApi, disabled: loading }]}
      >
        {loading && !historic ? (
          <Spinner accessibilityLabel='Small spinner' size='small' />
        ) : (
          renderHistoric
        )}
      </Card>
    </Layout.Section>
  )
}

export default Historic
