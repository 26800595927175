import { TextContainer, TextStyle, Button, Page, Card } from '@shopify/polaris'
import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'

import { apis } from 'src/utils/api'
import { isLoggedIn } from 'src/utils/loggedIn'
import { login } from 'src/utils/login'

interface AuthResponse {
  accessToken: string
  refreshToken: string
  expiresIn: number
}

const Login: React.FC = () => {
  const [loggedIn, setLoggedIn] = useState(isLoggedIn())
  const [hasFailed, setHasFailed] = useState(false)

  if (loggedIn) {
    return <Redirect to='/' />
  }

  const handleLoginClick = () => {
    window.gapi.auth2.authorize(
      {
        client_id: process.env.GOOGLE_CLIENT_ID,
        cookie_policy: 'single_host_origin',
        scope: 'email profile',
        response_type: 'code',
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      async (googleResponse: any) => {
        if (googleResponse && !googleResponse.error) {
          // google authentication succeed, now post data to server.
          const { data } = await apis.id.get<AuthResponse>(
            `/users/auth/google_oauth2/callback?${new URLSearchParams({
              ...googleResponse,
              redirect_uri: 'postmessage',
            })}`
          )
          login({
            accessToken: data.accessToken,
            refreshToken: data.refreshToken,
            expiresIn: data.expiresIn,
            type: 'snap-id',
          })
          setLoggedIn(true)
        }
        // google authentication failed
        setHasFailed(true)
      }
    )
  }

  return (
    <Page fullWidth title='You need to login to access this page.'>
      <Card sectioned>
        <TextContainer>
          <p>
            <Button onClick={handleLoginClick}>Login with Google</Button>
          </p>
          {hasFailed && (
            <p>
              <TextStyle variation='negative'>
                An error has occured. Please retry.
              </TextStyle>
            </p>
          )}
        </TextContainer>
      </Card>
    </Page>
  )
}

export default Login
