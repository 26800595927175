/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { ChangeEvent, FC, useEffect, useState } from 'react'

type TypeValue = string | number

interface Props {
  multiple?: boolean
  options: any[]
  value?: TypeValue
  onChange(value: TypeValue): void
  hasFirstOption?: boolean
  defaultValue?: TypeValue
}

const CellSelect: FC<Props> = ({
  multiple = false,
  options,
  value = undefined,
  onChange,
  hasFirstOption = true,
  defaultValue = undefined,
}) => {
  const [data, setData] = useState<TypeValue>(value || defaultValue || '')

  // With defaultValue, we set it by default to the cell
  useEffect(() => {
    if (defaultValue && defaultValue === data) {
      onChange(defaultValue)
    }
  }, [defaultValue])

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setData(event.target.value)
    onChange(event.target.value)
  }

  return (
    <select multiple={multiple} onChange={handleChange} defaultValue={data}>
      {hasFirstOption && <option value='' />}
      {options.map((option) => {
        const label = option?.label || option
        const optValue = option?.value || option

        return (
          <option value={optValue} key={optValue}>
            {label}
          </option>
        )
      })}
    </select>
  )
}

export default CellSelect
