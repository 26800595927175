/* eslint-disable import/no-cycle */
import moment from 'moment'

import {
  ColumnsErrorType,
  ValidationProps,
  SimpleOption,
  AdvanceOption,
} from './types'

export const formatMessage = (
  message: string,
  status = 0
): ValidationProps => ({ message, status: status === 0 ? 'error' : 'warn' })

/**
 * @function useTable
 * @description hook to retrieve column and rows
 * @param  {Array} data - the data from the file
 * @return {Object}
 */
export const useTable = (data: []) => {
  if (!data?.length) {
    return {
      columns: [],
      rows: [],
    }
  }

  const newData = [...data]

  return {
    columns: newData.splice(0, 1)[0],
    rows: [...newData],
  }
}

/**
 * @function validateHeader
 * @description Validate the header data (column)
 * @param  {Array} data - The data of the header
 * @param  {Boolean} [strict=false] - Force the column to stay at the same
 * position
 * @return {Object} - Columns' errors
 */
export const validateHeader = (
  data: string[],
  strict = false,
  configuration: any
): ColumnsErrorType => {
  const errors: ColumnsErrorType = {
    global: [],
    column: {},
    total: 0,
  }

  if (data.length !== configuration.length) {
    errors.global.push(formatMessage('All columns are required.'))

    errors.total += 1
  }

  // All the columns must be in the same place
  if (strict) {
    data.forEach((column: string, indexColumn: number) => {
      const columnName = configuration?.[indexColumn]?.header

      if (!column) {
        errors.column[indexColumn] = [
          formatMessage(`The column does not exist on this schema.`),
        ]
      } else if (!data.includes(columnName)) {
        errors.column[indexColumn] = [
          formatMessage(`The column ${columnName} is missing in the file.`),
        ]
        errors.total += 1
      } else if (column !== columnName) {
        errors.column[indexColumn] = [
          formatMessage(
            `The current column ${column} must be equal to ${columnName}.`,
            1
          ),
        ]
        errors.total += 1
      }
    })
  }

  return errors
}

/**
 * @function validRows
 * @description Validate the rows
 * @param  {Array} data - The data with columns and rows
 * @return {Object} - Rows' errors
 */
export const validRows = (data: any[], configuration: any[]) => {
  const newData = [...data]
  // removes the columns data
  newData.splice(0, 1)
  const errors: any = {}

  // loop on the rows
  newData.forEach((row, indexRow) => {
    // keep the error from the cells
    const cellErrors: any = {}
    // loop on the cells in the row
    row.forEach((cell: any, indexCell: number) => {
      const cellConfiguration = configuration[indexCell]?.cell

      const isRequired = cellConfiguration?.required
      const errorStatus = isRequired ? 0 : 1

      cellErrors[indexCell] = []

      if (isRequired && !cell) {
        cellErrors[indexCell].push(formatMessage('The cell is required'))
      }

      // Configuration with a cell's type
      if (cellConfiguration?.type && cell) {
        switch (cellConfiguration.type) {
          // Check the number value
          case 'number': {
            const formatedCell = Number.isNaN(cell)
              ? Number(cell.replaceAll(/(\s+)/g, ''))
              : cell

            if (Number.isNaN(formatedCell)) {
              cellErrors[indexCell].push(
                formatMessage('The cell must be a number', errorStatus)
              )
            }

            break
          }
          case 'float': {
            const replacement = cellConfiguration?.parser || '.'
            const invalidCaracter = replacement === '.' ? ',' : '.'
            const regCaracters = /[,.]/gm
            const matchMultiple = cell.match(regCaracters)

            // If multiple caracters exist in the string
            if (matchMultiple?.length > 2 || cell.includes(invalidCaracter)) {
              cellErrors[indexCell].push(
                formatMessage(
                  `The cell must be a float with with this format: 666${replacement}42`,
                  errorStatus
                )
              )
              break
            }

            // add configuration for user with other operator
            const formatedCell = cell.replaceAll(/(\s+)/g, '')

            if (Number.isNaN(Number(formatedCell))) {
              cellErrors[indexCell].push(
                formatMessage('The cell must be a number', errorStatus)
              )
            }

            break
          }
          case 'email': {
            const regex = /^[a-zA-Z0-9.!&*+=?_-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/gm
            const formatedCell = cell.trim()

            if (!formatedCell.match(regex)) {
              cellErrors[indexCell].push(
                formatMessage('The cell must be a valid email', errorStatus)
              )
            }

            break
          }
          case 'date': {
            // to export this behavior we have to change the date DD/MM/YYYY
            const date = cell.trim().split('/')

            const currentDate = moment(`${date[1]}-${date[0]}-${date[2]}`)

            // // Check the date
            if (!currentDate.isValid()) {
              cellErrors[indexCell].push(
                formatMessage('The cell must be a valid date', errorStatus)
              )
            }

            break
          }
          // Check the select option value
          case 'select': {
            const isStrict = Boolean(cellConfiguration?.strict)

            if ((cellConfiguration?.options as AdvanceOption[])?.[0]?.value) {
              const options =
                (cellConfiguration?.options as AdvanceOption[])?.map(
                  (option) => option.value
                ) || []

              if (
                (isStrict && !options.includes(cell)) ||
                (!isStrict && !options.includes(cell.toLowerCase()))
              ) {
                cellErrors[indexCell].push(
                  formatMessage(
                    `The field should be one of these values: ${options.reduce(
                      (acc, opt) => (!acc ? opt : `${acc}, ${opt}`),
                      ''
                    )}`
                  )
                )
              }
              break
            }

            if (
              (isStrict && !cellConfiguration?.options?.includes(cell)) ||
              (!isStrict &&
                !cellConfiguration?.options?.includes(cell.toLowerCase()))
            ) {
              cellErrors[indexCell].push(
                formatMessage(
                  `The field should be one of these values: ${(cellConfiguration?.options as SimpleOption[])?.reduce(
                    (acc, opt) => (!acc ? opt : `${acc}, ${opt}`),
                    ''
                  )}`
                )
              )
            }

            break
          }
          default:
            break
        }
      }

      // null will be returned if all is good
      // otherwise, an error object will be present
      const validateCell:
        | ValidationProps
        | null
        | undefined = cellConfiguration?.validate?.(cell, row)

      if (validateCell) {
        cellErrors[indexCell].push(validateCell)
      }

      // Removing the empty errors of the cell
      if (!cellErrors[indexCell].length) {
        delete cellErrors[indexCell]
      }
    }) // end of check cells

    // Set the cells errors in the row
    if (Object.keys(cellErrors).length) {
      errors[indexRow] = cellErrors
    }
  })

  return errors
}

export const hasAfterValidator = (configuration: any[]) => {
  const indexes: any = []

  configuration.forEach((element, index) => {
    if (element?.cell?.afterFormat) {
      indexes.push(index)
    }
  })

  return indexes
}
