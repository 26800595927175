import {
  Stack,
  Card,
  Navigation,
  Page,
  Link as PolarisLink,
  Banner,
  Link,
} from '@shopify/polaris'
import React from 'react'

import { logout as handleLogout } from 'src/utils/api'

const App = () => (
  <Page
    fullWidth
    title='Backoffice'
    primaryAction={<PolarisLink onClick={handleLogout}>Logout</PolarisLink>}
  >
    <Stack>
      <Stack.Item>
        <Navigation location='/'>
          <Navigation.Section
            title='Account data'
            items={[
              {
                url: '/accounts',
                label: 'Latest accounts',
              }
            ]}
          />
          {/* <Navigation.Section
            title='Tools'
            items={[
              {
                url: '/import-validator',
                label: 'Import validation',
              },
            ]}
          /> */}
          <Navigation.Section
            title='Dashboard'
            items={[
              {
                url: 'https://api.combohr.com/admin/cancellations',
                label: 'Subscription cancellation reasons',
              },
              {
                url: 'https://api.combohr.com/admin/reactivation',
                label: 'Billing reactivations',
              },
              {
                url: 'https://api.combohr.com/admin/integrations',
                label: 'Cash register',
              },
              {
                url: 'https://api.combohr.com/admin/collective_agreements',
                label: 'Collective agreements',
              },
              {
                url: 'https://api.combohr.com/admin/user_contract_dpaes',
                label: 'Dpaes',
              },
              {
                url: 'https://api.combohr.com/admin/min_wages',
                label: 'Country min wages',
              },
            ]}
          />
        </Navigation>
      </Stack.Item>
      <Stack.Item fill>
        <Card>
          <Card.Section>
            <Banner>
              Show more analytics on Google Analytics :
              <ul>
                <li>
                  <Link url='https://analytics.google.com/analytics/web/?authuser=1#/dashboard/LJAsE5V-TeK5GXqD7iuMjA/a26650238w295331050p256390938/'>
                    Dashboard
                  </Link>
                </li>
                <li>
                  <Link url='https://analytics.google.com/analytics/web/?authuser=1#/dashboard/IsmOXoSmSl2Vi52cZYwM5A/a26650238w295331050p256390938/'>
                    User actions
                  </Link>
                </li>
              </ul>
            </Banner>
          </Card.Section>
        </Card>
      </Stack.Item>
    </Stack>
  </Page>
)

export default App
