import React, { FC, useMemo } from 'react'

import { Account, Location as SnapAppLocation } from 'src/types/SnapApi'

import shapeConfigurationES from './config.es'
import shapeConfigurationFR from './config.fr'
import ImportContainer from './container'

type Props = {
  account: Account
  locations: SnapAppLocation[]
}

const ImportConfigurationContainer: FC<Props> = ({ account, locations }) => {
  const locationsData = useMemo(() => {
    const teamsIds: any[] = []
    const locationsIds: any[] = []

    locations.forEach((location) => {
      locationsIds.push({
        label: `${location.id}: ${location.name}`,
        value: `${location.id}`,
      })

      location.teams.forEach(({ id, name }) => {
        teamsIds.push({
          label: `${id}: ${name} - ${location.id}: ${location.name}`,
          value: `${id}`,
        })
      })
    })

    return { locationsIds, teamsIds }
  }, [locations])

  const configuration = useMemo(() => {
    const accountCountry = account.country
    const newConfiguration = [
      ...(accountCountry === 'ES'
        ? shapeConfigurationES
        : shapeConfigurationFR),
    ]

    // Locations
    if (newConfiguration[0]?.cell) {
      newConfiguration[0].cell.options = locationsData.locationsIds
    }

    // teams
    if (newConfiguration[1]?.cell) {
      newConfiguration[1].cell.options = locationsData.teamsIds
    }

    return newConfiguration
  }, [locationsData])

  return <ImportContainer account={account} configuration={configuration} />
}

export default ImportConfigurationContainer
