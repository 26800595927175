import { Icon } from '@shopify/polaris'
import React from 'react'
import { FaArrowDown, FaArrowUp } from 'react-icons/fa'

import styles from './index.module.css'

const Scroller: React.FC = () => (
  <div className={styles.Scroller}>
    <div>
      <button className={styles.Button} onClick={() => window.scrollTo(0, 0)}>
        <Icon source={FaArrowUp} />
      </button>
      <button
        className={styles.Button}
        onClick={() => window.scrollTo(0, window.outerHeight)}
      >
        <Icon source={FaArrowDown} />
      </button>
    </div>
  </div>
)

export default Scroller
