import React, { ChangeEvent, FC, useCallback, useState } from 'react'

type Props = {
  value?: string | number
  onChange: (value: string | number) => void
  onKeyPress?: (event: any) => void
  delay?: number
  defaultValue?: string | number
  type?: 'text' | 'number' | 'email' | 'tel' | 'date'
}

let timeout: number

const CellInput: FC<Props> = ({
  type = 'text',
  value = undefined,
  onChange,
  delay = 250,
  defaultValue = undefined,
  onKeyPress = undefined,
}) => {
  const [newValue, setNewValue] = useState<string | number>(
    value || defaultValue || ''
  )

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newEventValue = event.target.value

    setNewValue(newEventValue)

    if (timeout) {
      clearTimeout(timeout)
    }

    timeout = window.setTimeout(() => {
      onChange(newEventValue)
    }, delay)
  }

  const handleKeyPress = useCallback(
    (event) => {
      if (onKeyPress) {
        onKeyPress(event)
      }
    },
    [onKeyPress]
  )

  // remove this when the pattern will be developed for date
  const inputType = type === 'date' ? 'text' : type

  return (
    <input
      onChange={handleChange}
      value={newValue}
      type={inputType}
      onKeyPress={handleKeyPress}
    />
  )
}

export default CellInput
