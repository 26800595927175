/* eslint-disable import/no-cycle */
import * as PHONE from 'libphonenumber-js/max'

import { formatMessage } from '../helpers'

const countryCode: any = {
  FR: 'FR',
  EN: 'GB',
  ES: 'ES',
  IT: 'IT',
}

const validator = (cell: string, row: any[]) => {
  const languageValue = row[row.length - 1] || 'FR'

  if (!cell) {
    return null
  }

  if (PHONE.validatePhoneNumberLength(cell, countryCode[languageValue])) {
    return formatMessage('The phone number is not correct.')
  }

  return PHONE.isValidPhoneNumber(cell, countryCode[languageValue])
    ? null
    : formatMessage('The phone number is not valid')
}

export const PhoneFormatter = (cell: any, row: any) => {
  const languageValue = row[row.length - 1] || 'FR'

  return (
    (cell &&
      !PHONE.validatePhoneNumberLength(cell, countryCode[languageValue]) &&
      PHONE.parsePhoneNumber(cell, countryCode[languageValue])?.number) ||
    cell
  )
}

export default validator
