import { format, parseISO, getTime } from 'date-fns'

// Sort rows from oldest date to newest
export function sortCohortPerDate<T extends { date: string }>(array: T[]): T[] {
  return ([...array] as T[]).sort((a, b) => {
    return getTime(parseISO(a.date)) - getTime(parseISO(b.date))
  })
}

export function formatDate(date: string | Date): string {
  return format(typeof date === 'string' ? parseISO(date) : date, 'E MMM d')
}

export function formatTime(date: string | Date): string {
  return format(typeof date === 'string' ? Date.parse(date) : date, "hh'h'")
}

export function formatDateTime(
  date: string | Date | null,
  withMin = false
): string {
  if (!date) {
    return ''
  }

  return format(
    typeof date === 'string' ? parseISO(date) : date,
    `d MMM yyyy 'at' HH'h'${withMin ? 'mm' : ''}`
  )
}
