import { Card, Layout, TextStyle } from '@shopify/polaris'
import moment from 'moment'
import Papa from 'papaparse'
import React, { FC, useCallback, useEffect, useState } from 'react'

import { Account } from 'src/types/SnapApi'
import { apis } from 'src/utils/api'

import Form from './form'
import { validateHeader, validRows, hasAfterValidator } from './helpers'
import styles from './index.module.css'
import ImportTable from './table'
import { ConfigurationType } from './types'

type Props = {
  account: Account
  configuration: ConfigurationType[]
}

type APIError = {
  status?: number
  statusText?: string
  message?: string
  url?: string
}

type Response = {
  status: string
}

// Contains all the logic there
const ImportContainer: FC<Props> = ({ account, configuration }) => {
  const [error, setError] = useState<APIError>({})
  const [csvData, setCsvData] = useState<any | null>(null)
  const [csvErrors, setCsvErrors] = useState<any | null>()
  const [csvColumnErrors, setCsvColumnErrors] = useState<any | null>(null)
  const [file, setFile] = useState<any>()
  const [canDownload, setCanDownload] = useState(false)
  const [response, setResponse] = useState<Response | null>(null)

  const onbeforeunload = useCallback(
    (event: any) => {
      // Can reload or naviguate to previous or next page without a file
      if (!file) {
        return
      }

      event.returnValue = 'Are you sure to leave this page ?'

      return event.returnValue
    },
    [file]
  )

  useEffect(() => {
    window.addEventListener('beforeunload', onbeforeunload, { capture: true })

    return () =>
      window.removeEventListener('beforeunload', onbeforeunload, {
        capture: true,
      })
  }, [file])

  // Call to format on the first mount of all the data
  const formatAfterValidation = (data: any) => {
    // Check if the validator exists
    const indexes = hasAfterValidator(configuration)

    if (indexes.length) {
      const newData = [...data]
      // loop on the rows
      data.forEach((row: any, indexRow: number) => {
        // The firt row is the name of the columns
        if (indexRow > 0) {
          // loop only on the column that need to be formated
          indexes.forEach((indexColumn: number) => {
            if (newData[indexRow][indexColumn]) {
              // set the new data on the cell
              newData[indexRow][indexColumn] = configuration[
                indexColumn
              ]?.cell?.afterFormat?.(newData[indexRow][indexColumn], row)
            }
          })
        }
      })
      // set the new data on the table
      setCsvData(newData)
    }
  }

  const validateData = (data: any) => {
    setCsvData(data)
    // Make the first errors here
    const columnErrors = validateHeader(data[0], true, configuration)
    const rowsErrors = validRows(data, configuration)

    setCsvColumnErrors(columnErrors)
    setCsvErrors(rowsErrors)

    // Allow to download the file
    setCanDownload(
      Boolean(!columnErrors.total && !Object.keys(rowsErrors).length)
    )

    return { columnErrors, rowsErrors }
  }

  const handleImport = useCallback(
    async (csvFile) => {
      // set the file
      setFile(csvFile)

      // Parse the csvFile
      Papa.parse(csvFile, {
        complete(results) {
          if (results?.data) {
            validateData(results.data)

            formatAfterValidation(results.data)
          }
        },
      })
    },
    [account]
  )

  // Format the json to CSV
  const formatCSVFile = async () => {
    // Transforms the json data to csv
    const csv = await Papa.unparse(csvData, { delimiter: ';' })

    const name = `${moment().format('YYYY-MM-DD')}-${account.subdomain}.csv`

    // Create a new blob file to send it to the back
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })

    // Create the formData
    const formData = new FormData()
    formData.append('csv_file', blob, name)

    return { formData, blob, name }
  }

  // download the formated csv file
  const downloadFile = async () => {
    const { blob, name } = await formatCSVFile()

    const link = window.document.createElement('a')

    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', name)
      link.style.visibility = 'hidden'
      window.document.body.appendChild(link)
      link.click()
      window.document.body.removeChild(link)
    }
  }

  // Upload the new formated csv file
  const handleUploadFile = async () => {
    const { formData } = await formatCSVFile()

    // Send the foramted csv to the back
    await apis.snapApp
      .post(`backoffice/accounts/${account.id}/imports`, formData)
      .then((result) => {
        setResponse(result.data)
        setError({})
      })
      .catch((err) => {
        const { response } = err

        setError({
          status: response?.status,
          statusText: response?.statusText,
          message: response?.data?.message,
          url: response?.config?.url,
        })
      })
  }

  // Update the cell value
  const updateCell = useCallback(
    (posX, posY, value) => {
      // the csvData contains the columns so we need to add 1 to posY
      const newData = [...csvData]
      newData[posY + 1][posX] = value

      const { rowsErrors } = validateData(newData)

      // Temporary to get clean solution
      if (
        !rowsErrors?.[posY]?.[posX] &&
        configuration[posX]?.cell?.afterFormat
      ) {
        // Find row
        const row = newData[posY + 1]

        // Get the formatedValue
        const formatedValue = configuration[posX]?.cell?.afterFormat?.(
          value,
          row
        )

        const newValidateData = [...newData]
        newValidateData[posY + 1][posX] = formatedValue

        setTimeout(() => {
          setCsvData(newValidateData)
        }, 0)
      }
    },
    [csvData, setCsvData, setCsvColumnErrors, setCsvErrors]
  )

  // Update the column value
  const updateColumn = useCallback(
    (index, value) => {
      const newData = [...csvData]

      // Set the new value of the column
      newData[0][index] = value

      validateData(newData)
    },
    [csvData]
  )

  const removeRow = useCallback(
    (indexRow: number) => {
      const newCsvData = [...csvData]
      // Add one because the columns is the index 0
      newCsvData.splice(indexRow + 1, 1)

      validateData(newCsvData)
    },
    [csvData]
  )

  const removeColumn = useCallback(
    (indexColumn: number) => {
      const newCsvData = [...csvData]

      newCsvData.forEach((row) => {
        row.splice(indexColumn, 1)
      })

      validateData(newCsvData)
    },
    [csvData]
  )

  // At least, add the last column
  const addColumn = useCallback(() => {
    const newCsvData = [...csvData]

    newCsvData.forEach((row) => {
      row.push(String())
    })

    validateData(newCsvData)
  }, [csvData])

  const clearColumn = useCallback(
    (indexColumn: number, clearAll = false) => {
      const newCsvData = [...csvData]

      newCsvData.forEach((row: any, indexRow: number) => {
        // Do not clear the column name
        if (!clearAll && indexRow === 0) {
          return
        }

        row.forEach((cell: any, indexCell: number) => {
          if (indexCell === indexColumn) {
            newCsvData[indexRow][indexCell] = String()
          }
        })
      })

      validateData(newCsvData)
    },
    [csvData]
  )

  const addRow = () => {
    const newCsvData = [...csvData]
    // set default values for cells or only string
    const newRow = configuration.map(
      (config) => config?.cell?.defaultValue || String()
    )

    newCsvData.push(newRow)

    validateData(newCsvData)
  }

  // Duplicate row
  const duplicateLastRow = () => {
    const newCsvData = [...csvData]

    const formatedLastRow = csvData[csvData.length - 1].map(
      (cellData: any, index: number) => {
        const currentColumnConfiguration = configuration[index]

        if (currentColumnConfiguration?.cell?.required) {
          return (
            cellData ||
            currentColumnConfiguration?.cell?.defaultValue ||
            String()
          )
        }

        return String()
      }
    )

    newCsvData.push(formatedLastRow)

    validateData(newCsvData)
  }

  // TODO: refacto this function to link with download formated file
  const downloadModel = () => {
    const column = configuration.map((config) => config.header)
    const row = configuration.map(
      (config) => config?.cell?.defaultValue || String()
    )

    const csv = Papa.unparse([column, row])

    // Create a new blob file to send it to the back
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })

    const link = window.document.createElement('a')

    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', `${account.subdomain}.csv`)
      link.style.visibility = 'hidden'
      window.document.body.appendChild(link)
      link.click()
      window.document.body.removeChild(link)
    }
  }

  return (
    <>
      <Layout.Section>
        {error?.status && (
          <div className={styles.TextError}>
            <TextStyle variation='negative'>
              Something wrong happened! {error?.status} {error?.message} on the
              call: {error?.url}
            </TextStyle>
          </div>
        )}
        <Form
          onSubmit={handleImport}
          uploadFile={handleUploadFile}
          canDownload={canDownload}
          downloadFile={downloadFile}
          downloadModel={downloadModel}
        />
      </Layout.Section>

      <Layout.Section>
        <Card title={`List of the employees${file ? ` ${file.name}` : ''}`}>
          {Boolean(csvColumnErrors?.total) && (
            <Card.Section>
              <div className={styles.TextError}>
                <TextStyle variation='negative'>
                  There are {csvColumnErrors.total} errors to fix before to
                  upload the file in the columns
                </TextStyle>
              </div>
            </Card.Section>
          )}

          {Boolean(csvErrors && Object.keys(csvErrors).length) && (
            <Card.Section>
              <div className={styles.TextError}>
                <TextStyle variation='negative'>
                  There are {Object.keys(csvErrors).length} errors to fix before
                  to upload the file in the rows
                </TextStyle>
              </div>
            </Card.Section>
          )}

          {response && (
            <Card.Section>
              <TextStyle variation='positive'>
                Your request was {response?.status}, please take a look at the
                historic list, below to the table, to check the status of your
                import.
              </TextStyle>
            </Card.Section>
          )}

          <Card.Section>
            <ImportTable
              data={csvData}
              errors={csvErrors}
              columnErrors={csvColumnErrors}
              updateCell={updateCell}
              updateColumn={updateColumn}
              removeRow={removeRow}
              removeColumn={removeColumn}
              clearColumn={clearColumn}
              addRow={addRow}
              duplicateRow={duplicateLastRow}
              addColumn={addColumn}
              configuration={configuration}
            />
          </Card.Section>
        </Card>
      </Layout.Section>
    </>
  )
}

export default ImportContainer
